import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import batteryImage from "./images/camera-battery-air-travel.jpg";

import styles from "../styles/post.module.scss";

const PhotoGearTravel = () => (
  <Layout>
    <SEO title="Photo Gear and Air Travel" />
    <div className={styles.postContainer}>
      <article className={styles.post}>
        <h2>Batteries and Battery Packs</h2>
        <img src={batteryImage} alt="a camera battery at an airport" />
        <p>
          When travelling, not the most unusual activity for photographers, we
          sometimes need to be extra careful what to place in the checked in
          luggage and what to bring in the hand luggage. Especially batteries
          for cameras and flashes can cause delays or be a danger to air travel
          safety.
        </p>
        <p>
          Lithium Ion Batteries are common components or phones and all kinds of
          consumer electronics. They need to be kept outside of checked in
          luggage, because they are a dangerous material when punctured or
          damaged
        </p>
        <p>
          Most famously the Samsung Galaxy Note 7 had faulty batteries that
          would cause the phone to combust. This brought a lot of attention to
          batteries in general
        </p>
        <p>
          Batteries need to be in the hand luggage because this is where they
          can be put out safely, whereas the crew might not be able to get to
          the checked in luggage in time on a flight, which is in the cargo hold
          of a plane.
        </p>
        <h2>Pelican or Sealed Cases</h2>
        <p>
          Water proof cases can cause delays and you might be questioned or
          asked to open your luggage, as these cases cause suspicion because
          they seal air tight and are therefor harder for the staff and canine
          units to check for drugs and explosives.
        </p>
      </article>
    </div>
  </Layout>
);

export default PhotoGearTravel;
